<template>
  <header class="relative z-20">
    <div v-if="currentRouteName === 'home2'" class="goat" @click="goToForm() " />
    <div
      v-if="open"
      @click="open = false"
      class="fixed w-full h-full z-50 bg-black bg-opacity-10 flex justify-end items-start"
    >
      <div
        class="bg-black text-white p-12 w-[90%] shadow-2xl rounded-bl-3xl relative z-50"
      >
        <img
          @click="open = false"
          src="@/assets/images/close.png"
          class="absolute w-8 left-4 top-4 z-50"
        />
        <ul class="text-5xl pl-12">
          <li class="pb-6">
            <a href="https://www.muscador.fr/" target="_blank"
              >LE SITE<br />MUSCADOR</a
            >
          </li>
          <li>
            <span
              class="border-white border-t-2 w-full h-[3px] pt-6 block"
            ></span>
          </li>
          <li><router-link to="/produits">NOS PRODUITS</router-link></li>
        </ul>
      </div>
    </div>
    <router-link
      to="/home2"
      class="block absolute top-0 left-0 w-80 h-8 mobile:h-48 cursor-pointer mobile:z-50"
    />
    <div
      class="w-full mobil:max-w-[250px] m-auto absolute left-0 mobile:left-[4rem] middle:left-[9rem] mt-[1rem] px-[2rem] z-20"
    >
      <img
        v-if="currentRouteName != 'home'"
        @click="open = !open"
        src="@/assets/images/burger.png"
        class="mobile:hidden w-[3rem] absolute right-[1.1rem] cursor-pointer z-40 top-[4.6rem]"
      />
      <router-link to="/home2"
        ><img
          src="@/assets/images/logo22.png"
          class="w-[12.5rem] mobile:w-[10rem] m-auto mobile:m-0"
      /></router-link>
    </div>

    <div class="fond-top flex items-center text-white relative z-10"></div>
    <div
      class="w-full max-w-screen-2xl hidden mobile:flex justify-end m-auto px-[1.5rem] text-white mt-[-2.8rem] relative z-40"
    >
      <ul
        v-if="currentRouteName != 'home' && currentRouteName != 'ask'"
        class="nav relative !z-40"
      >
        <li>
          <a href="https://www.muscador.fr/" target="_blank"
            >LE SITE MUSCADOR</a
          >
        </li>
        <li>|</li>
        <li><router-link to="/produits">NOS PRODUITS</router-link></li>
      </ul>
    </div>
  </header>
</template>

<script>
export default {
  data() {
    return {
      open: false,
    };
  },
  methods : {
    goToForm() {
      this.$router.push("/form");
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss">

@media screen and (max-width: 768px) {
  .goat {
    @apply absolute w-full h-20 top-[680px] cursor-pointer;
  }
}

@media screen and (max-width: 375px) {
  .goat {
    @apply absolute  w-full h-16 top-[595px] cursor-pointer;
  }
}

.Cookie {
  background-color: var(--primary-color-bg) !important;
  background: var(--primary-color-bg) !important;
  color: var(--primary-color-txt) !important;
  font-size: 0.8em;
  line-height: 1.5em;
  width: 100%;
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  left: unset !important;
  right: 5% !important;

  @media screen and (max-width: 1024px) {
    max-width: unset;
    left: 0 !important;
    right: unset !important;
  }

  @media screen and (max-width: 768px) {
    font-size: 0.75em;
  }

  a {
    color: var(--primary-color-txt) !important;
  }

  ul {
    list-style-type: none !important;
  }

  button {
    font-size: 1.1em;
    margin-right: 20px;
    font-weight: 400;
    min-width: 150px;

    @media screen and (max-width: 768px) {
      width: 100px;
      margin-right: 10px;
    }
  }
}
</style>
