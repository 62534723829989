import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import "./assets/styles/index.scss";

import FormBuilder from "lucien-form-builder";
Vue.use(FormBuilder);

import { Simplert } from "vue2-simplert-plugin";
import "vue2-simplert-plugin/dist/vue2-simplert-plugin.min.css";
Vue.use(Simplert);

Vue.config.productionTip = false;

console.log("process.env.NODE_ENV", process.env.NODE_ENV, process.env);



Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
