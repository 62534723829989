<template>
  <footer>
    <div
      v-if="currentRouteName != 'home' && currentRouteName != 'ask'"
      class="w-full border-t-2 mobile:py-[.3rem] mobile:px-[1rem] border-black relative z-40"
    >
      <ul class="nav-footer p-4 mobile:p-0">
        <li><a href="https://oxoformdocs.fra1.digitaloceanspaces.com/660cf7d3b7d944c915bf1a28/reglement.pdf" target="_blank">RÈGLEMENT DU JEU</a></li>
        <li class="hidden mobile:block">|</li>
        <li><a href="https://oxoformdocs.fra1.digitaloceanspaces.com/660cf7d3b7d944c915bf1a28/politique.pdf" target="_blank">POLITIQUE DE CONFIDENTIALITÉ</a></li>
        <li class="hidden mobile:block">|</li>
        <li><router-link to="/mentions">MENTIONS LÉGALES</router-link></li>
        <li class="hidden mobile:block">|</li>
        <li><router-link to="/contact">CONTACTEZ-NOUS</router-link></li>
      </ul>
    </div>

    <div
      class="bg-black py-4 mobile:py-0 w-full text-center mobile:h-[3rem] flex flex-wrap sanitaire items-center justify-center text-white relative z-20"
    >
      <div
      v-if="currentRouteName != 'home' && currentRouteName != 'ask'"
        class="border-t-2 w-[90vw] py-1 bg-black mobile:hidden border-white -mt-6"
      ></div>
      L'abus d'alcool est dangereux pour la santé, à consommer avec modération
    </div>
  </footer>
</template>
<script>
export default {
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

