<template>
  <div id="app" :class="currentRouteName">
    <headerElem />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <FooterElem />
  </div>
</template>

<script>
import HeaderElem from "@/components/Header";
import FooterElem from "@/components/Footer";

const loadUmamiScript = (websiteId) => {
  console.log(websiteId, "websiteId");
  const scriptElement = document.createElement("script");
  scriptElement.async = true;
  scriptElement.defer = true;
  scriptElement.src = "https://eu.umami.is/script.js";
  scriptElement.dataset.websiteId = websiteId;
  scriptElement.dataset.doNotTrack = "true";
  scriptElement.dataset.autoTrack = "true";
  document.body.append(scriptElement);
};
loadUmamiScript("20c53ddb-1e56-43cd-9c6b-891988e1f9bb");

export default {
  name: "App",
  data: function () {
    return {};
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  components: {
    HeaderElem,
    FooterElem,
  },
};
</script>
